import {Notify} from '../helpers/notify'
import {AxiosError as BaseAxiosError} from 'axios'
import {useCallback} from 'react'
import {LoadingOptionsI, useLoading} from "../contexts/loading.context";
import Cookie from "js-cookie";

const catchError = (error: BaseAxiosError<{ error: string }>) => {
    console.log({error: error.response?.data})
    if (error.response?.status === 401) {
        Cookie.remove('authed')
        window.location.href = '/';
    }

}

type AxiosError = BaseAxiosError<{ statusCode: number; message: string }>

export const useQueryWithLoading = <TRes = any, TReq = null>(callback: (data: TReq) => Promise<TRes>, loadingOptions?: LoadingOptionsI): (data: TReq) => Promise<TRes> => {

    const {startLoading, stopLoading} = useLoading()
    const fetch = useCallback(async (data: TReq) => {

        try {
            startLoading(loadingOptions)
            const res = await callback(data)
            stopLoading()
            return res
        } catch (e: any) {
            const {response} = e as AxiosError
            catchError(e)
            console.log(response?.data)
            Notify.Error(response?.data.message)
            stopLoading()
            return null
        }
        // eslint-disable-next-line
    }, [])

    return fetch as (data: TReq) => Promise<TRes>
}
