import {Header} from "./header";
import {About} from "./about";
import {Banner} from "./banner";
import {Events} from "./events";
import {Gallery} from "./gallery";
import {Home} from "./home";

export const Pages = [
    {
        Element: Home,
        title: "Home",
        path: '/',
        visible: false
    },{
        Element: Header,
        title: "Header",
        path: '/header',
        visible: false
    },
    {
        Element: Banner,
        title: "Banner",
        path: '/banner',
        visible: true
    },
    {
        Element: About,
        title: "About",
        path: '/about',
        visible: true
    },
    {
        Element: Events,
        title: "Events",
        path: '/events',
        visible: true
    },
    {
        Element: Gallery,
        title: "Gallery",
        path: '/gallery',
        visible: true
    }
]
