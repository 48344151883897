import React from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {BasePage} from "./pages/basePage";

function App() {


    return (
        <Router>
            <BasePage/>
        </Router>

    );
}

export default App;
