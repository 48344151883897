import React, {Suspense} from 'react';
import {Pages} from "../index";
import {Route, Routes} from "react-router-dom";
import {Loading} from "../../components/loading";

export const MyRoutes = () => (
    <div className="page">
        <Suspense fallback={<Loading isLoading={true}/>}>
            <Routes>
                {Pages.map(Page => (Page.visible || Page.path === '/') &&
                    <Route path={Page.path} key={Page.path} element={<Page.Element/>}/>)}
            </Routes>
        </Suspense>
    </div>

)

