import React, {FC, FormEvent, useState} from 'react';
import Cookie from "js-cookie"
import "./signScreen.css"
import {AuthApi} from "../../services/auth";
import {useQueryWithLoading} from "../../hooks/useQueryWithLoading";
import {AxiosResponse} from "axios";

const generatePass = () => {
    const now = new Date(Date.now())
    return `${now.getHours()}${now.getDate()}`
}
export const SignScreen: FC = () => {
    const [value, setValue] = useState<string>('')
    const signInFetch  = useQueryWithLoading<AxiosResponse<{ message: string; },any> | undefined, { pass: string }>(AuthApi.signIn ,{title:"Signing",message:"please wait"})

    const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        console.log(value, generatePass())
        if(!value)return
        const res = await signInFetch({pass:value})
        console.log(res?.data)
        if(res?.data ){
            Cookie.set('authed', res?.data?.message || '', {expires: 1/24})
            window.location.reload();
        }
    }

    return (
        <div className="container">
            <form onSubmit={onSubmit}>
                <input value={value} type="password" onChange={(e) => setValue(e.target.value)}/>

            </form>

        </div>
    );
};
