import React from 'react';
import {Pages} from "../../pages";
import {Link, useLocation} from "react-router-dom";
import "./dashboard.css"

export const Dashboard = () => {
    const location = useLocation()
    console.log(location)

    return (
        <div className={`dashboard ${location.pathname === '/'?"home":''}`}>
            <ul>
                <li key="home"><Link  to='/'>Home</Link></li>
                {Pages.map(page => page.visible &&
                    <li key={page.path}><Link className={page.path === location.pathname ? "active" : ""}
                              to={page.path}>{page.title}</Link></li>
                )}
            </ul>

        </div>
    );
};
