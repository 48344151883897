import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Cookie from "js-cookie";
import {SignScreen} from "./pages/sign/signScreen";
import {LoadingProvider} from "./contexts/loading.context";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const signed = Cookie.get('authed')


root.render(
    <React.StrictMode>
        <ToastContainer />
        <LoadingProvider>
            {
                signed
                    ? <App/>
                    : <SignScreen/>
            }
        </LoadingProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
