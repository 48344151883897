import React, {createContext, FC, PropsWithChildren, useContext, useState} from 'react';
import {Loading} from "../components/loading";

// Create a context for the loading state
// @ts-ignore
const LoadingContext = createContext<{
    isLoading:boolean,
    startLoading:(options?: LoadingOptionsI) =>void,
    stopLoading:(options?: LoadingOptionsI) =>void,
}>();


export interface LoadingOptionsI {
    message?: string,
    title?: string
}

// Create a provider component
export const LoadingProvider: FC<PropsWithChildren> = ({children}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState<LoadingOptionsI>(
    //     {
        //     message :'getting ready getting ready getting ready getting ready getting ready getting ready getting ready getting ready getting ready getting ready '
        // }
    )

    const startLoading = (options?: LoadingOptionsI) => {
        setIsLoading(true);
        setOptions(options || {})
    }
    const stopLoading = (options?: LoadingOptionsI) => {
        setIsLoading(false);
        setOptions(options || {})
    }
    return (
        <LoadingContext.Provider value={{isLoading, startLoading, stopLoading}}>
            {children}
            <Loading isLoading={isLoading} {...options}/>
        </LoadingContext.Provider>
    );
};

// Create a custom hook to use the loading context
export const useLoading = () =>  useContext(LoadingContext)
