import React, {FC} from 'react';
import "./loading.css"

interface LoadingProps {
    isLoading: boolean
    title?: string
    message?: string
}

export const Loading: FC<LoadingProps> = ({isLoading, title, message}) => {
    return (
        <div className={`loading-container ${isLoading ? "on" : "off"}`}>
            <div className={`loading-content ${isLoading ? "on" : "off"}`}>
                {/*<div className="spinner">*/}
                {/*    <i className="fa fa-spinner fa-6x" aria-hidden="true"/>*/}
                {/*</div>*/}
                <div className="loading-indicator">
                    <div className="spinner"/>
                </div>
                <h2 className="title"> {title || 'Loading ...'}</h2>
                {
                    message && <div className="message"> {message}</div>
                }
            </div>

        </div>
    );
};
