import React, {FC} from 'react';
import {Dashboard} from "../../components/dashboard";
import {MyRoutes} from "./routes";
import {useLocation} from "react-router-dom";
import "./page.css"


export const BasePage: FC = () => {
    const location = useLocation()

    return (
        <div className={`container ${location.pathname === '/' ? "home" : ''}`}>
            <div className="dashboard-container">
                <Dashboard/>
            </div>
            <MyRoutes/>
        </div>
    );
}


