import axios, {AxiosResponse} from "axios";
import Cookie from "js-cookie";
import {baseUrl} from "../constants";


const getHeaders = () => ({
    token: Cookie.get('authed'),
    "Content-Type": "application/json",
    "Accept": "application/json",
})

export const API = {
    get: async <T = any>(url = ''): Promise<AxiosResponse<T> | undefined> =>
        await axios.get<T>(`${baseUrl}${url}`, {
            headers: getHeaders()
        }),
    remove: async <T = any>(url = ''): Promise<AxiosResponse<T> | undefined> =>
        await axios.delete<T>(`${baseUrl}${url}`, {
            headers: getHeaders()
        }),
    post: async <T = any>(url = '', data: { [K: string]: any }): Promise<AxiosResponse<T> | undefined> =>
        await axios.post<T>(`${baseUrl}${url}`, data, {
            headers: getHeaders()
        }),
}
